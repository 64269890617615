body {
  /*background: repeating-linear-gradient(338deg, #85660c, transparent 1px);*/
  /*background-color: darkgrey;*/
}
.container {
  perspective: 1000px;
  perspective-origin: 50% 50%;
  font-family: "fontawesome";
  height: 50vh;
}
.container .cube {
  position: relative;
  left: 25vw;
  top: 50%;
  height: 200px;
  width: 200px;
  transform-style: preserve-3d;
  cursor: pointer;
  transform: rotateX(135deg) rotateY(-217deg) rotateZ(-88deg);
}
.container .cube div {
  position: absolute;
  box-sizing: border-box;
  padding: 10px;
  height: 100%;
  width: 100%;
  border: 2px solid #eee;
  color: #fff;
}
.container .cube div span {
  font-size: 32px;
  font-family: "fontawesome";
}
.container .cube .front {
  background: green;
  background: black;
  transform: translateZ(100px);
}
.container .cube .back {
  background: orange;
  background: black;
  transform: translateZ(-100px) rotateY(180deg);
}
.container .cube .right {
  background: purple;
  background: black;
  transform-origin: top right;
  transform: rotateY(-270deg) translateX(100px);
}
.container .cube .left {
  background: orange;
  background: black;
  transform-origin: center left;
  transform: rotateY(270deg) translateX(-100px);
}
.container .cube .top {
  background: green;
  background: black;
  transform-origin: top center;
  transform: rotateX(-270deg) translateY(-100px);
}
.container .cube .bottom {
  /*background: purple;*/
  background: black;
  transform-origin: bottom center;
  transform: rotateX(270deg) translateY(100px);
}

/*DOT ALIGNMENT */
.container .cube .front span,
.container .cube .left span:nth-child(1),
.container .cube .right span:nth-child(1) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.container .cube .top span:nth-child(1),
.container .cube .left span:nth-child(2),
.container .cube .right span:nth-child(2),
.container .cube .bottom span:nth-child(1) {
  position: absolute;
  top: 35px;
  left: 35px;
}
.container .cube .top span:nth-child(2),
.container .cube .left span:nth-child(3),
.container .cube .right span:nth-child(3),
.container .cube .bottom span:nth-child(2) {
  position: absolute;
  bottom: 35px;
  right: 35px;
}
.container .cube .right span:nth-child(4),
.container .cube .bottom span:nth-child(3) {
  position: absolute;
  top: 35px;
  right: 35px;
}
.container .cube .right span:nth-child(5),
.container .cube .bottom span:nth-child(4) {
  position: absolute;
  bottom: 35px;
  left: 35px;
}
.container .cube .back pre {
  font-size: 32px;
  font-family: "fontawesome";
  margin: 0;
}
.container .cube .back .firstPre {
  position: absolute;
  top: 45px;
  left: 49px;
}
.container .cube .back .secondPre {
  position: absolute;
  bottom: 45px;
  right: 46px;
}
@keyframes animate {
  25% {
    transform: rotateX(45deg) rotateY(65deg) rotateZ(35deg);
    top: 0%;
  }
  50% {
    transform: rotateX(145deg) rotateY(165deg) rotateZ(135deg);
    top: 30%;
  }
  75% {
    transform: rotateX(276deg) rotateY(256deg) rotateZ(246deg);
    top: 45%;
  }
  100% {
    transform: rotateX(376deg) rotateY(356deg) rotateZ(346deg);
    top: 60%;
  }
}
